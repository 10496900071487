import React, { FC, useCallback, useState } from "react";

import dynamic from "next/dynamic";

import { PlayButton } from "@/components/PlayButton";
import { Button } from "@/components/ui";
import { VideoModalProps } from "@/components/VideoModal";
import { useUserInfo } from "@/core/Auth/hooks";
import CheckListIcon from "@/features/public/home/assets/CheckListIcon";
import { screens } from "@/ui/postcss/custom-media";

import { BecomeMemberType } from "../../types";
import Subtitle from "../Subtitle";

import styles from "./styles.module.scss";

const FeefoRating = dynamic(() => import("@/components/FeefoRating"), {
  ssr: false,
});

const VideoModal = dynamic<VideoModalProps>(
  () => import("@/components/VideoModal").then((module) => module.VideoModal),
  {
    ssr: false,
  }
);

type Props = {
  content: BecomeMemberType;
};

const BecomeMember: FC<Props> = ({ content }) => {
  const auth = useUserInfo();
  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleShowVideoModal = useCallback(
    (v: boolean) => () => setShowVideoModal(v),
    []
  );

  const handleFeefoClick = useCallback(
    () => window.open("https://www.feefo.com/en-GB/reviews/mashroom", "_blank"),
    []
  );

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <Subtitle className={styles.title} content={content.title} />
        <ul>
          {content.list.map((v) => (
            <ListItem key={v.item} item={v.item} />
          ))}
        </ul>
        <div className={styles.buttonContainer}>
          {auth?.id == null && (
            <Button
              className={styles.button}
              nextLinkProps={{
                href: content.button_url,
                prefetch: false,
              }}
              shape="rounded"
              size="lg"
              theme="primary"
            >
              {content.button_text}
            </Button>
          )}
          <FeefoRating/>
        </div>
      </div>

      <div className={styles.videoContainer}>
        <picture>
          <source
            media={`(min-width: ${screens.phoneN + 1}px)`}
            srcSet={`${content.cover.sizes.medium}.webp, ${content.cover.sizes.large}.webp 2x`}
            type="image/webp"
          />
          <source
            media={`(min-width: ${screens.phoneN + 1}px)`}
            srcSet={`${content.cover.sizes.medium}, ${content.cover.sizes.large} 2x`}
            type="image/jpeg"
          />
          <source
            media={`(max-width: ${screens.phoneN}px)`}
            srcSet={`${content.cover_mobile.url}.webp`}
            type="image/webp"
          />
          <source
            media={`(max-width: ${screens.phoneN}px)`}
            srcSet={content.cover_mobile.url}
            type="image/jpeg"
          />
          <img
            alt={content.title}
            decoding="async"
            loading="lazy"
            src={content.cover.sizes.medium}
          />
        </picture>
        <PlayButton
          className={styles.playButton}
          onClick={handleShowVideoModal(true)}
        />
      </div>

      {showVideoModal && (
        <VideoModal
          onClose={handleShowVideoModal(false)}
          videoId={content.youtube_video_id}
        />
      )}
    </section>
  );
};

export default BecomeMember;

const ListItem = ({ item }: { item: string }) => (
  <li>
    <CheckListIcon />
    <span dangerouslySetInnerHTML={{ __html: item }}></span>
  </li>
);
