import React from "react";

const CheckListIcon = () => (
  <svg
    fill="none"
    height="25"
    viewBox="0 0 25 25"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 12.5054L11.1216 16.5595L17.4468 9.47949"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.99847"
    />
    <rect
      height="21.9832"
      rx="6.99464"
      stroke="currentColor"
      strokeWidth="1.99847"
      width="21.9832"
      x="1.49923"
      y="1.51413"
    />
  </svg>
);

export default CheckListIcon;
